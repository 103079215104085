@font-face {
  font-family: "montserrat-light";
  src: local("montserrat-light"),
    url(./fonts/montserrat-light.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat-regular";
  src: local("montserrat-regular"),
    url(./fonts/montserrat-regular.ttf) format("truetype");
}
body {
  font-family: "montserrat-regular", Arial, Helvetica, sans-serif !important;
}
main {
  min-height: 95vh;
}
.active {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease;
  transform: translateX(0);
}
.navbar {
  background-color: #55622b;
}
.primary-color {
  background-color: #000 !important;
}
.btn-default {
  background-color: #55622b;
}
#welcome-message {
  padding-top: 3%;
  margin-top: 100px;
}
.title-image {
  margin-top: 50px;
  width: 75%;
  position: absolute;
  right: 5%;
}
@media (max-width: 768px) {
  #welcome-message {
    padding-top: 3%;
    margin-top: 0px;
  }
  .title-image {
    margin-top: 0px;
  }
}

.colored-section-white {
  background-color: #fff;
  min-height: fit-content;
}
.signup-bg {
  background-image: url("img/gravel.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 95vh;
}
.login-card {
  background-color: rgba(0, 0, 0, 0.616) !important;
  border-color: #f8f9fa !important;
}
.login-card-img-top {
  width: 70%;
}
.input-group-prepend span {
  width: 50px;
  background-color: #f8bb58;
  color: black;
  border: 0 !important;
}
.form-group {
  padding: 5px 5px;
}

.login_btn {
  color: black;
  background-color: #f8bb58;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.company-title {
  font-size: 1.2rem;
  font-weight: 700 !important;
  color: #fdcf85;
}

.big-heading {
  color: #55622b;
  font-weight: 500 !important;
}

.text-heading-black {
  color: black;
  font-size: 0.75rem;
  line-height: 1.5;
  margin-left: -40px;
  font-weight: 400;
}
hr.line {
  width: 50%;
  border: 1px solid black;
  margin: 0;
}
.btn-yellow {
  background-color: #f8bb58 i !important;
  padding: 8px !important;
}
.bold-h3 {
  font-size: 1.2rem;
  font-weight: 700 !important;
  margin-bottom: 5px !important;
}
.bold-h4 {
  font-size: 1.5rem;
  font-weight: 700 !important;
}
.btn-blue-grey {
  padding: 8px !important;
}
.table thead th {
  border-bottom: 2px solid #000 !important;
}
p {
  margin-bottom: 5px !important;
  line-height: 1.3;
}
.green-eye {
  color: #55622b;
}
#footer-text {
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.crdHover :hover {
  background-color: #f8bb58;
}
.orderHov {
  color: black;
}

.drop-container p {
  color: red;
  text-align: center;
}

.drop-containerb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 150px;
  border: 4px dashed #78909c;
}
.drop-message {
  text-align: center;
  color: #78909c;
  font-family: Arial;
  font-size: 20px;
}
.policy-text {
  font-size: 14px;
}
